<template>
  <batch-dialog
    :visible="visible"
    @close="closeAction"
    @cancel="cancelAction"
    @onOk="onOk"
    @onDialogOpened="onDialogOpened"
    @onDialogClosedAfterAction="onDialogClosedAfterAction"
    :record-operation="operation"
    :record-list="recordList"
    :is-selected-record="isSelectedRecord"
    :batch-operation-status="batchOperationStatus"
    :alert="internalAlert"
    :progress="progressText"
  ></batch-dialog>
</template>

<script>
// model
import {
  batchRecordStatus,
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";

// mixins
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
import { actionResultType } from "@/model/action/actionModel";
import { moduleItemNavigatable } from "@/mixins/shared/navigatable/moduleItemNavigatable";
import { colorTheme } from "@/design/colors/mdColors";
import { getErrorColor } from "@/design/colors/Color";
import { batchDialogMixin } from "@/mixins/shared/batch/batchDialogMixin";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";

export default {
  name: "BatchDeleteRecordsDialog",
  components: {
    BatchDialog: () => import("@/components/shared/core/dialogs/BatchDialog")
  },
  mixins: [
    promptAbleMixin,
    progressAbleMixin,
    moduleItemNavigatable,
    batchDialogMixin,
    alertableMixin
  ],
  props: {
    recordList: {
      type: Array,
      default: () => []
    },
    deleteEvent: {
      type: Function,
      required: true
    },
    deleteEventCompleted: {
      type: Function,
      required: true
    },
    isPurgeOperation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSelectedRecord: null,
      isCanceled: false,
      batchOperationStatus: [],
      criticalColor: colorTheme.error
    };
  },

  computed: {
    /**
     * Delete Record Operation
     * @return {{name: string, icon: string, label: string}} Move Record Operation
     */
    operation() {
      return this.isPurgeOperation
        ? findRecordOperation(recordOperation.Purge)
        : findRecordOperation(recordOperation.Delete);
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operation?.label}`;
    },

    /**
     * get Color Class
     * @return {Color}
     */
    criticalColorClass() {
      return getErrorColor();
    }
  },

  methods: {
    /**
     * Delete Progress Text
     * @param record
     * @param index
     * @return {string}
     */
    deleteProgressText(record, index) {
      return `Deleting ${record.name} \n  ${index} of ${this.recordList.length}`;
    },

    /**
     * Performs Delete Record
     */
    async onOk() {
      try {
        this.clearAlert();
        for (let i = 0; i < this.recordList.length; i++) {
          if (!this.isCanceled) {
            const record = this.recordList[i];
            this.isSelectedRecord = record;

            this.progressText = this.deleteProgressText(record, i + 1);

            const result = await this.deleteEvent(record);

            if (result?.type === actionResultType.success) {
              this.setStatus(record.id, batchRecordStatus.Completed, "Success");
              //await this.autoCloseDialog(result.message);
            } else if (result?.type === actionResultType.abort) {
              this.setStatus(record.id, batchRecordStatus.Canceled, "Canceled");
              //this.closeDialog();
            } else {
              this.setStatus(
                record.id,
                batchRecordStatus.Failed,
                result.message
              );
              this.internalAlert = this.createAlert(
                this.alertTypeName.error,
                this.formatAlertError(this.action, result.message),
                false
              );
            }
          }
        }

        this.internalAlert = this.createAlertSuccess(
          `${this.action} Operation Completed`,
          false
        );
      } catch (e) {
        this.closeProgress();
        this.isCanceled = true;
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.isCanceled = false;
        this.closeProgress();
        this.isSelectedRecord = undefined;
        //this.closeDialog();
      }
    },

    /**
     * Event on Dialog Closed After Action Performed
     * @return {Promise<void>}
     */
    async onDialogClosedAfterAction() {
      await this.deleteEventCompleted();
    }
  }
};
</script>
